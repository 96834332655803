$primary: #5fc5ff;

body {
}

.dropdown {
  position: relative;
  ._header {
    cursor: pointer;
    font-weight: 500;
    padding: 8px;
  }
  ._options {
    position: absolute;
    padding: 10px 0;
    max-height: 250px;
    overflow: auto;
    ._option {
      padding: 5px;
      cursor: pointer;
    }
    ._option:hover {
      background: #5fc4ff31;
    }
  }
}

.oracle-dropdown {
  width: 50%;
  max-width: 500px;
  ._header {
    background: #f4faff;
    border-radius: 4px;
    letter-spacing: -0.5px;
    color: #59748a;
    // padding: 4px;
    border: 1px solid $primary;
    > span {
      margin-right: 10px;
    }
  }
  ._options {
    background: #bce0fc;
    width: 100%;
    border-radius: 0 0 5px 5px;
    color: #85a5c2;
    font-weight: 500;
    font-style: italic;
  }
  ._option:hover {
    color: #59748a;
  }
}
.connect-dropdown {
  ._header {
    background: $primary;
    display: inline-block;
    border-radius: 4px;
    padding: 6px 25px;
    color: white;
    text-transform: uppercase;
    transition: 0.2s all linear;
    // letter-spacing: -0.5px;
  }
  ._header:hover {
    color: rgba(0, 0, 0, 0.712);
  }
  ._options {
    margin-top: 10px;
    background: #eef7fe;
    border-radius: 8px;
    padding: 8px 0;
    color: #59748a;
    width: 100%;
    font-weight: 500;
  }
}

@media only screen and (max-width: 800px) {
  .oracle-dropdown {
    width: 80%;
  }
}
