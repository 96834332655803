.home {
  z-index: 1000;
  padding-top: 100px;
  min-height: calc(100vh - 301px);
  > img:first-child {
    width: 150px;
  }
  .heading {
    color: #59748a;
    font-size: 150%;
    font-weight: 800;
  }
  > div {
    margin: 20px;
  }
}

.dropdown {
  z-index: 2000 !important;
  // min-width: 200px;
  > div {
    min-width: 100px;
  }
}
