.App {
  text-align: center;
}
body {
  font-family: karla;
  color: #59748a;
}

.header {
  padding: 20px 2vw;
  max-width: 1150px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.headerScry {
  padding: 20px 21vw;//0vw;
  max-width: 1150px;
  color:skyblue;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content:center;
  font-size: large;
}

.logo {
  margin-right: auto;
  align-items: left;
}

.flex {
  display: flex;
  align-items: center;
}
.flex-x {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-x-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-y {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.button:active {
  position: relative;
  top: 1px;
}

.button {
  cursor: pointer;
}

.footer {
  background: #5fc5ff;
  color: rgba(255, 255, 255, 0.678);
  padding: 10px 0;
  // position: fixed;
  width: 100%;
  bottom: 0;
  margin-top: 50px;
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 22px;
      margin: 8px;
      height: 22px;
    }
    img.small {
      width: 18px;
      height: 18px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .App > .header {
    padding-right: 5vw;
  }
}
