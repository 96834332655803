$primary: #5fc5ff;
.details {
  background: #eef7fe;

  .upper-div {
    background: #eef7fe;
    flex-wrap: wrap;
    padding: 30px 2vw;
    margin: auto;
    max-width: 850px;
    > div {
      width: 200px;
      padding: 30px 20px;
      input {
        background: transparent;
        border: none;
        outline: none;
        color: #59748a;
        opacity: 0.7;
        font-family: Karla;
        text-align: center;
        font-weight: 600;
        letter-spacing: -0.66px;

        font-size: 105%;
        //   margin: 10px;
        margin-top: 10px;
        width: 150px;
      }
    }
  }
  .block {
    margin: 20px 0;
    border-radius: 15px;
    color: white;
    background: #4780f5;
    font-size: 120%;
    font-weight: 600;
    position: relative;
    > div:first-child {
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    > div:nth-child(2) {
      color: #bce0fc;
      font-size: 98%;
    }
    > div:last-child {
      position: absolute;
      bottom: 0;
      width: 80%;
      margin-left: 10%;
      left: 0;
      height: 5px;
      background: white;
    }
  }
}

.sub-button {
  background: #5fc5ff;
  border-radius: 10px;
  padding: 15px 20px !important;
  color: white;
  margin-left: auto;
  text-transform: uppercase;
  font-weight: 600;
  > img {
    width: 15px;
    margin-right: 10px;
  }
}
.charts {
  margin: 30px auto;
  max-width: 850px;

  .chart {
    background: white;
    border-radius: 20px;
    border: 1px solid #5fc5ff;
  }

  ._title {
    margin-top: 20px;
    margin-bottom: -50px;
    font-weight: 500;
    > div:first-child {
      font-size: 120%;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  ._header {
    text-align: left;
    font-weight: 800;
    font-size: 200%;
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .upper-div {
    justify-content: center;
    > div {
      padding: 15px !important;
    }
    .sub-button {
      margin: 10px;
    }
    .block {
      font-size: 99%;
      width: 36% !important;
      padding: 5% 4% !important;
      margin: 2%;
    }
  }
}
