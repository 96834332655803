$singleWidth: 250px;
$primary: #5fc5ff;

.percentage {
  width: 13vw;
  height: 13vw;
}

.block2 {
  // width: 55%;
  // max-width: 00px;
  min-width: calc(3 *#{$singleWidth} + 40px);
  > div:first-child {
    width: 360px;
    background: #f4faff;
    border-radius: 10px;
    width: calc(
      100% - #{$singleWidth} - 40px - (100% - #{$singleWidth}* 3) / 2
    );
    padding: 20px;
    // padding: 20px;
    > div {
      margin: 8px;
      font-weight: 500;
      color: #59748a;
      opacity: 0.9;
    }
  }
}

.block4 {
  // width: 55%;
  // max-width: 00px;
  min-width: calc(3 *#{$singleWidth} + 40px);
  > div:first-child {
    width: 360px;
    background: #ffffff;
    border-radius: 10px;
    font-weight: 500;
     font-size: medium;
     font-family: Karla;
     color: #ffffff;
      opacity: 0.9;
    width: calc(
      100% - #{$singleWidth} - 40px - (100% - #{$singleWidth}* 3) / 2
    );
    padding: 20px;
    padding: 25px;
    > div {
    //  margin: 8px;
      font-weight: 500;
      color: #59748a;
      font-family: Karla;
      opacity: 0.9;
    }
  }
  
}
.block1 {
  // width: 55%;
  min-width: calc(3 *#{$singleWidth} + 40px);
}

.feed {
  .block {
    background: white;
    border-radius: 10px;
    // padding: 10px;
    box-shadow: 0px 0px 8px #00000012;
    // margin: 20px;
    width: $singleWidth;
    height: 110px;
    font-weight: 600;
    color: #59748a;
    > div:first-child {
      opacity: 0.8;
      text-transform: uppercase;
    }
    > div:last-child {
      font-family: Averia Libre;
      font-size: 150%;
    }
  }

  .radio-input {
    > div {
      width: 150px;
      font-weight: 600;

      > div {
        cursor: pointer;
        > span:first-child {
          margin-right: 8px;
        }
      }
      ._input {
        border: 2px solid $primary;
        border-radius: 20px;
        display: inline-block;
        width: 10px;
        height: 10px;
      }
      ._input.active {
        background: $primary;
      }
    }
  }
  ::placeholder {
    color: #59748a;
  }
  .text-input {
    input {
      background: transparent;
      border: none;
      border-bottom: 2px solid $primary;
      outline: none;
      color: #59748a;
      opacity: 0.7;
      font-family: Karla;
      font-weight: 600;
      letter-spacing: -0.66px;

      font-size: 105%;
      //   margin: 10px;
      margin-top: 10px;
      width: 150px;
    }
  }

  .parent {
    background: #eef7fe;
    padding: 20px;
    > div:nth-child(2) {
      color: #85a5c2;
      font-weight: 500;
      letter-spacing: -0.4px;
      margin-bottom: 30px;
      img {
        margin-right: 8px;
      }
    }
    > div {
      margin: 5px;
    }
    > div:first-child {
      background: white;
      border-radius: 30px;
      padding: 5px 15px;
      color: #85a5c2;
      font-weight: 500;
    }
  }
  .percentage {
    position: relative;
    .VictoryContainer {
      //   position: absolute;
    }
    .center {
      background: #59748a;
      position: absolute;
      border-radius: 500px;
      width: 50%;
      height: 50%;
      color: #eef7fe;
      > div:first-child {
        font-family: Averia Libre;
        font-size: 150%;
      }
      > div:last-child {
        font-size: 90%;
      }
    }
  }
}

.block3 {
  z-index: 1000;
  > div {
    margin: 10px 15px;
  }
}

.subscribe {
  padding: 10px 18px;
  border-radius: 8px;
  background: $primary;
  color: white;
  text-transform: uppercase;
}

.overview {
  padding: 20px 2vw;
  margin: auto;
  max-width: 1150px;
  ._body {
    flex-wrap: wrap;
    margin: 0 -10px 0 -10px;
    height: 500px;
    overflow: auto;
    align-items: flex-start;
    padding-top: 20px;
  }
  .feed-block {
    position: relative;
    background: #bce0fc;
    height: 120px;
    width: 250px;
    margin: 20px 10px;
    cursor: pointer;
    background: #bce0fc 0% 0% no-repeat padding-box;
    box-shadow: inset 2px 2px 2px #59748a80, 2px 6px 5px #0000001a;
    border-radius: 10px;
    > img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    > div:first-child {
      margin-top: 20px;
      > span {
        display: inline-block;
      }
      > span:first-child {
        background: #86b3f8;
        border-radius: 0 4px 4px 0;
        color: white;
        padding: 4px 8px;
        img {
          width: 10px;
          margin-right: 10px;
        }
      }
      > span:last-child {
        font-size: 120%;
        color: #59748a;
        padding: 0 15px;
         font-family: Karla;
      }
    }
  }
  ._header {
    > div:first-child {
      font-weight: 700;
      font-size: 130%;
      text-transform: uppercase;
    }
    .search {
      border: 2px solid #59748a5d;
      border-radius: 5px;
      padding: 3px;
      margin-right: 20px;
      img {
        margin: 0 5px;
      }
      input {
        background: transparent;
        border: none;
        outline: none;
        color: #59748a;
        opacity: 0.7;
        font-family: Karla;
        font-weight: 600;
        letter-spacing: -0.66px;

        font-size: 105%;
        //   margin: 10px;
        width: 150px;
      }
    }
  }
}

.confirm {
  width: 100px;
  margin-top: 20px;
}
.ReactModalPortal {
  .ReactModal__Overlay {
    backdrop-filter: blur(2px);
  }
  input {
    margin: 15px;
    text-align: center;
    background: transparent;
    border: none;
    border-bottom: 2px solid $primary;
    outline: none;
    color: #59748a;
    opacity: 0.7;
    font-family: Karla;
    font-weight: 600;
    letter-spacing: -0.66px;

    font-size: 105%;
    //   margin: 10px;
    margin-top: 10px;
    width: 150px;
  }
  .ReactModal__Content {
    width: 250px;
    margin: auto;
    height: 280px;
    border: 1px solid $primary !important;
    border-radius: 5px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@media only screen and (max-width: 800px) {
  .block1 {
    min-width: calc(3 * 100px + 40px);
    .block {
      width: 100px;
    }
  }
  .block2 {
    flex-direction: column;
    > div:first-child {
      width: 90% !important;
    }
  }
  .percentage {
    width: 40vw;
    height: 40vw;
  }

  .overview {
    .feed-block {
      width: 45%;
    }
    ._header {
      > div:first-child {
        margin-bottom: 20px;
      }
      flex-direction: column;
    }
    ._body {
      margin-top: 20px;
    }
  }
}
